import { useState } from "react";
import { Store } from "../../types/store";
import { Theme } from "../../types/theme";
import { Button } from "../../atoms/button";

type Props = {
  sessionMaterials: {
    setSessionToken: () => Promise<string>;
    store: Store;
    host: string;
  }
  backupTheme: Theme;
  onRewindStatus: boolean;
  setOnRewindStatus: (data: boolean) => void;
  onBackupStatus: boolean;
};

export const RewindButton: React.FC<Props> = ({
  sessionMaterials: { setSessionToken, store, host },
  backupTheme,
  onRewindStatus,
  setOnRewindStatus,
  onBackupStatus,
}) => {
  const [rewindThemeLoadings, setRewindThemeLoadings] = useState<number[]>([]);

  const rewind = (id: number) => {
    if(onBackupStatus || onRewindStatus) return ;

    setOnRewindStatus(true);
    setRewindThemeLoadings((prev) => [...prev, id]);
    setSessionToken().then((sessionToken: string) => {
      fetch(
        `${
          process.env.REACT_APP_LAMBDA_ENDPOINT as string
        }/sqs?host=${host}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "X-SHOPIFY-TOKEN": sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            themes: [id],
            store: store.id,
            type: "rewind",
          }),
        }
      )
        .then(() => {
          setOnRewindStatus(false);
          setRewindThemeLoadings((prev) => [...prev].filter((el) => el !== id));
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
          setOnRewindStatus(false);
          setRewindThemeLoadings((prev) => [...prev].filter((el) => el !== id));
          window.location.reload();
        });
    });
  };

  return (
    <Button
      text="復元"
      handleOnClick={() => rewind(backupTheme.id)}
      type={onBackupStatus || onRewindStatus ? "disabled" : "secondary"}
      loading={
        rewindThemeLoadings.indexOf(backupTheme.id) !== -1
      }
      tooltipText={onBackupStatus ? "バックアップ中のため他の操作は行えません" : null}
    />
  );
};
