import { useEffect, useState } from "react";
import "./style.scss";
import { Backups } from "../../types/backup";
import { Store } from "../../types/store";
import { ThemeWithMemo } from "../../types/theme";
import { ShopifyThemeBackups } from "../shopifyThemeBackups";

type Props = {
  sessionMaterials: {
    setSessionToken: () => Promise<string>;
    store: Store;
    host: string;
  };
  setOnBackup: (data: Backups[]) => void;
  onBackupStatus: boolean;
  setOnBackupStatus: (data: boolean) => void;
};

export const ListBackups: React.FC<Props> = ({
  sessionMaterials: { setSessionToken, store, host },
  setOnBackup,
  onBackupStatus,
  setOnBackupStatus,
}) => {
  const sessionMaterials = {
    setSessionToken,
    store,
    host,
  };

  const [backupThemes, setBackupThemes] = useState<Backups[]>([]);
  const [onRewindStatus, setOnRewindStatus] = useState<boolean>(false);
  const [onInitialize, setOnInitialize] = useState<boolean>(false);
  const [initialRequest, setInitialRequest] = useState<boolean>(true);

  useEffect(() => {
    listThemes();
  }, [] && [onBackupStatus]);

  const listThemes = () => {
    setSessionToken().then((sessionToken: string) => {
      fetch(
        `${
          process.env.REACT_APP_LAMBDA_ENDPOINT as string
        }/stores/${store.id.toString()}/themes?host=${host}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "X-SHOPIFY-TOKEN": sessionToken,
          },
        }
      )
        .then((res) => res.json())
        .then((data: { backups: Backups[] }) => {
          setBackupThemes(data.backups);

          // backupThemesが空の場合は初期化中、5秒後に再度リストを取得する
          if (data.backups.length === 0) {
            setOnInitialize(true);
            setTimeout(() => {
              listThemes();
            }, 5000);
            return;
          } else {
            setOnInitialize(false);
          }

          const unCompletedBackups = data.backups.filter(
            (backup) => backup.completed_at === null
          );
          setOnBackup(unCompletedBackups);
          setOnBackupStatus(unCompletedBackups.length > 0);

          if(unCompletedBackups.length === 0) {
            fetch(
              `${
                process.env.REACT_APP_LAMBDA_ENDPOINT as string
              }/backups/themes/rename?host=${host}`,
              {
                method: "POST",
                mode: "cors",
                headers: {
                  "X-SHOPIFY-TOKEN": sessionToken,
                },
              }
            )
            .then((res) => res.json())
            .then((data) => {
              switch (data.message) {
                case "changed":
                  // もう１度リストを取得する
                  listThemes();
                  break;

                case "notChanged":
                  break;

                default:
                  break;
              }
            })

            fetch(
              `${
                process.env.REACT_APP_LAMBDA_ENDPOINT as string
              }/backups/themes/deleted?host=${host}`,
              {
                method: "POST",
                mode: "cors",
                headers: {
                  "X-SHOPIFY-TOKEN": sessionToken,
                },
              }
            )
            .then((res) => res.json())
            .then((data) => {
              switch (data.message) {
                case "changed":
                  listThemes();
                  break;

                case "notChanged":
                  break;

                default:
                  break;
              }
            })
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const initialBackups = () => {
    if(!initialRequest) return
    if(!store.is_first_install) {
      fetch(`${process.env.REACT_APP_LAMBDA_ENDPOINT as string}/sqs`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          storeId: store.id,
          type: "appInstallation",
        }),
      })
      .then((res) => res.json())
      .then((_data) => {
        setInitialRequest(false);
      })
      .catch((err) => console.log(err));
    }
  }
  initialBackups();


  return (
    <div className="listBackups">
      <div className="listBackups__header">
        <div className="listBackups__header__title">バックアップ一覧</div>
      </div>
      <div className="listBackups__body">
        <div>
          {onInitialize && (
            <div className="listBackups__popup">
              <div className="listBackups__popup__title">初回バックアップ中</div>
              <small className="listBackups__popup__small">初回バックアップ中のためしばらくお待ちください。</small>
            </div>
          )}
          {backupThemes
            .flatMap((backupTheme) => ({
              ...backupTheme.themes[0],
              memo: backupTheme.memo,
            }))
            .reduce((acc, theme) => {
              let existence = false;
              const newThemes = acc.map((prev) => {
                if (prev.shopify_id === theme.shopify_id) {
                  existence = true;
                  return { ...prev, themes: [...prev.themes, theme] };
                } else {
                  return prev;
                }
              });
              if (existence) {
                return newThemes;
              } else {
                return [
                  ...newThemes,
                  {
                    shopify_id: theme.shopify_id,
                    shopify_name: theme.shopify_name ?? "",
                    created_at: theme.created_at,
                    memo: theme.memo,
                    themes: [theme],
                  },
                ];
              }
            }, [] as { shopify_id: string; shopify_name: string; created_at: Date; memo: string; themes: ThemeWithMemo[] }[])
            .map((shopifyTheme, index) => (
              <ShopifyThemeBackups
                sessionMaterials={sessionMaterials}
                shopifyTheme={shopifyTheme}
                key={index}
                onRewindStatus={onRewindStatus}
                setOnRewindStatus={setOnRewindStatus}
                onBackupStatus={onBackupStatus}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
