import { useEffect } from "react";

export const OAuth: React.FC = () => {
  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    const shop = query.get("shop") ?? "";
    const code = query.get("code") ?? "";
    fetch(`${process.env.REACT_APP_LAMBDA_ENDPOINT as string}/stores`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop: shop,
        code: code,
      }),
    })
    .then((res) => res.json())
    .then((data: { confirmationUrl: string; storeId: number }) => {
      const confirmationUrl = data.confirmationUrl;
      window.location.assign(confirmationUrl);
    })
    .catch((err: any) => console.log(err));
  }, []);

  return <></>;
};
