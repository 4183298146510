import "./style.scss";
import { ThemeWithMemo } from "../../types/theme";
import { BsPencil } from "react-icons/bs";
import { useState } from "react";
import { RewindButton } from "../rewindButton";
// import { DeleteButton } from "../deleteButton";
import { Store } from "../../types/store";


type Props = {
  sessionMaterials: {
    setSessionToken: () => Promise<string>;
    store: Store;
    host: string;
  };
  themeBackup: ThemeWithMemo;
  onRewindStatus: boolean;
  setOnRewindStatus: (data: boolean) => void;
  onBackupStatus: boolean;
};

export const ShopifyThemeBackup: React.FC<Props> = ({
  sessionMaterials: { setSessionToken, store, host },
  themeBackup,
  onRewindStatus,
  setOnRewindStatus,
  onBackupStatus,
}) => {
  const sessionMaterials = {
    setSessionToken,
    store,
    host,
  };

  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [memoValue, setMemoValue] = useState<string>(themeBackup.memo || "");

  const datetimeFormatte = (datetime: Date) => {
    return (
      datetime.getUTCFullYear() +
      "/" +
      (datetime.getUTCMonth() + 1).toString() +
      "/" +
      datetime.getUTCDate() +
      " " +
      ("00" + datetime.getUTCHours()).slice(-2) +
      ":" +
      ("00" + datetime.getUTCMinutes()).slice(-2)
    );
  };

  const editMemo = () => {
    if(memoValue === "") return ;

    setSessionToken().then((sessionToken: string) => {
      fetch(
        `${
          process.env.REACT_APP_LAMBDA_ENDPOINT as string
        }/backups/edit?host=${host}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "X-SHOPIFY-TOKEN": sessionToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            backupId: [themeBackup.backup],
            memo: memoValue,
            target: "memo",
          }),
        }
      )
        .then((res) => res.json())
        .then((_data) => {
          setOnEdit(false);
        })
        .catch((err) => {
          console.error(err);
          setOnEdit(false);
        });
    });
  }

  return (
    <li className="shopifyThemeBackup__item">
      <div className="shopifyThemeBackup__item__left">
        <div className="shopifyThemeBackup__item__left__backup">
          <div className="shopifyThemeBackup__item__left__backup--memo">
            {onEdit ? (
              <>
                <input
                  type="text" value={memoValue}
                  className="shopifyThemeBackup__item__left__backup--input"
                  onChange={(e) => setMemoValue(e.target.value)}
                  maxLength={20}
                />
                <button
                  className={
                    `shopifyThemeBackup__item__left__backup--save ${memoValue === "" ? "shopifyThemeBackup__item__left__backup--save--disabled" : ""}`
                  }
                  onClick={editMemo}
                >
                  保存
                </button>
              </>
            ) : (
              <>
              {memoValue}
                <span
                  className="shopifyThemeBackup__item__left__backup--pencil"
                  onClick={() => setOnEdit(true)}
                >
                  <BsPencil size={16} />
                </span>
              </>
            )}
          </div>
          <div className="shopifyThemeBackup__item__left__backup--date">
            created_at: {datetimeFormatte(new Date(themeBackup.created_at))}
          </div>
        </div>
      </div>
      <div className="shopifyThemeBackup__item__right">
        <RewindButton
          sessionMaterials={sessionMaterials}
          backupTheme={themeBackup}
          onRewindStatus={onRewindStatus}
          setOnRewindStatus={setOnRewindStatus}
          onBackupStatus={onBackupStatus}
        />
      </div>
    </li>
  );
};
