import { useEffect } from "react";
import "./style.scss";
import { Backups } from "../../types/backup";
import MoonLoader from "react-spinners/MoonLoader";
import { Store } from "../../types/store";

type Props = {
  setSessionToken: () => Promise<string>;
  store: Store;
  host: string;
  backup: Backups[];
  setOnBackup: (onbackup: Backups[]) => void;
  setOnBackupStatus: (data: boolean) => void;
};

export const OnBackup: React.FC<Props> = ({
  setSessionToken,
  store,
  host,
  backup,
  setOnBackup,
  setOnBackupStatus,
}) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setSessionToken().then((sessionToken: string) => {
        fetch(
          `${
            process.env.REACT_APP_LAMBDA_ENDPOINT as string
          }/stores/${store.id.toString()}/themes?host=${host}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "X-SHOPIFY-TOKEN": sessionToken,
            },
          }
        )
          .then((res) => res.json())
          .then((data: { backups: Backups[] }) => {
            const unCompletedBackups = data.backups.filter(
              (backup) => backup.completed_at === null
            );
            setOnBackup(unCompletedBackups);
            setOnBackupStatus(unCompletedBackups.length > 0);
          })
          .catch((err) => {
            console.error(err);
          });
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <></>
    // <div className="onBackup">
    //   <div className="onBackup__popup">
    //     <div className="onBackup__popup__title">バックアップ中</div>
    //     <small className="onBackup__popup__small">バックアップ中のため他の操作は行えません</small>
    //     {backup[0]
    //       ? backup[0].themes.map((theme) => {
    //           return (
    //             <div className="onBackup__popup__theme" key={theme.id}>
    //               {theme.completed_at ? (
    //                 <div
    //                   style={{
    //                     width: "22px",
    //                     height: "22px",
    //                     backgroundColor: "#1872bc",
    //                     borderRadius: "11px",
    //                     marginTop: "4px",
    //                     color: "white",
    //                     lineHeight: "22px",
    //                     verticalAlign: "middle",
    //                     textAlign: "center",
    //                   }}
    //                 >
    //                   &#10004;
    //                 </div>
    //               ) : (
    //                 <MoonLoader
    //                   color="#1872bc"
    //                   loading
    //                   speedMultiplier={0.3}
    //                   size={16}
    //                   cssOverride={{
    //                     marginTop: "4px",
    //                   }}
    //                 />
    //               )}
    //               <p className="onBackup__popup__theme__name">
    //                 {theme.shopify_name}
    //               </p>
    //             </div>
    //           );
    //         })
    //       : null}
    //   </div>
    // </div>
  );
};
