import "./style.scss";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";
import { Tooltip } from "react-tooltip";

type Props = {
  text: string;
  handleOnClick: () => void;
  type: "primary" | "secondary" | "warning" | "disabled";
  loading?: boolean;
  tooltipText?: string | null;
};

export const Button: React.FC<Props> = ({
  text,
  handleOnClick,
  type,
  loading,
  tooltipText,
}) => {
  const override = css`
    display: block;
    vertical-align: bottom;
  `;

  return (
    <>
      {loading === true ? (
        <div className={`button button--loading`}>
          復元中
          <BarLoader
            color="#1872bc"
            loading={true}
            height={3}
            width="100%"
            css={override}
          />
        </div>
      ) : (
        <div className={`button button--${type}`} onClick={handleOnClick} data-tooltip-id="tooltip" data-tooltip-content={tooltipText}>
          {text}
          {tooltipText &&
            <Tooltip id="tooltip" style={{backgroundColor: "#a9a9a9"}} />
          }
        </div>
      )}
    </>
  );
};
