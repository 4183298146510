import LogRocket from "logrocket";

import { useEffect, useState } from "react";
import "./style.scss";
import { Buffer } from "buffer";

import createApp, {
  AppBridgeState,
  ClientApplication,
} from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { getSessionToken } from "@shopify/app-bridge-utils";

import { Store } from "../../types/store";
// import { CreateBackup } from "../../components/createBackup";
import { Backups } from "../../types/backup";
import { OnBackup } from "../../components/onBackup";
import { ListBackups } from "../../components/listBackups";
// import { ShopifyTheme } from "../../types/theme";

export const Home: React.FC = () => {
  const [store, setStore] = useState<Store | null>(null);
  const [onBackup, setOnBackup] = useState<Backups[]>([]);
  const [onBackupStatus, setOnBackupStatus] = useState<boolean>(false);
  // const [themes, setThemes] = useState<ShopifyTheme[]>([]);
  const [app, setApp] = useState<ClientApplication<AppBridgeState> | null>(
    null
  );

  LogRocket.init(process.env.REACT_APP_LOGROCKET ?? "");

  const params = new URLSearchParams(window.location.search);
  const shop = params.get("shop") ?? "";
  const host = Buffer.from(shop).toString("base64");

  const createAppHost = params.get("host") ?? "";

  useEffect(() => {
    fetch(
      `${
        process.env.REACT_APP_LAMBDA_ENDPOINT as string
      }/checkInstallation?host=${host}`,
      {
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((data: { store: Store | null }) => {
        if (data.store) {
          setApp(
            createApp({
              apiKey: process.env.REACT_APP_SHOPIFY_API_KEY ?? "",
              host: createAppHost,
            })
          );
          setStore(data.store);
        } else {
          const shop = params.get("shop") ?? "";
          const array = new BigUint64Array(1);
          window.crypto.getRandomValues(array);
          const state = array[0].toString(16);
          if (window.top === window.self) {
            window.location.assign(
              `https://${shop}/admin/oauth/authorize?client_id=${
                process.env.REACT_APP_SHOPIFY_API_KEY ?? ""
              }&scope=read_themes,write_themes&redirect_uri=${
                process.env.REACT_APP_HOST as string
              }/oauth&state=${state}&grant_options[]=value`
            );
          } else {
            Redirect.create(
              createApp({
                apiKey: process.env.REACT_APP_SHOPIFY_API_KEY ?? "",
                host: createAppHost,
              })
            ).dispatch(
              Redirect.Action.REMOTE,
              `https://${shop}/admin/oauth/authorize?client_id=${
                process.env.REACT_APP_SHOPIFY_API_KEY ?? ""
              }&scope=read_themes,write_themes&redirect_uri=${
                process.env.REACT_APP_HOST as string
              }/oauth&state=${state}&grant_options[]=value`
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sessionMaterials = {
    setSessionToken: () => getSessionToken(app as ClientApplication<AppBridgeState>),
    store: store as Store,
    host: host,
  }

  return app && store ? (
    <div className="home">
      {/* <CreateBackup
        setSessionToken={() => getSessionToken(app)}
        setOnBackupStatus={setOnBackupStatus}
        store={store}
        themes={themes}
        setThemes={setThemes}
      /> */}
      {onBackupStatus && (
        <OnBackup
          setSessionToken={() => getSessionToken(app)}
          store={store}
          host={host}
          backup={onBackup}
          setOnBackup={setOnBackup}
          setOnBackupStatus={setOnBackupStatus}
        />
      )}
      <ListBackups
        sessionMaterials={sessionMaterials}
        setOnBackup={setOnBackup}
        onBackupStatus={onBackupStatus}
        setOnBackupStatus={setOnBackupStatus}
      />
    </div>
  ) : null;
};
